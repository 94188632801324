import { Code, X } from '@phosphor-icons/react';
import { useCallback, useState } from 'react';
import { get, post } from 'src/api/requests';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Button } from 'src/components/Button';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Modal } from 'src/components/Modal';
import { Option, SelectBox } from 'src/components/SelectBox';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Agent } from 'src/interfaces/agent.interface';
import { FormNumber } from 'src/interfaces/number.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';

const providers = [{ value: 'twilio', label: 'Twilio' }];

interface LinkNumberProps {
  onClose?: () => void;
  refreshData?: () => void;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  agents?: Option[];
  accountConnections?: Option[];
}

export const LinkNumber = ({
  onClose = () => {},
  refreshData = () => {},
  modalOpen,
  setModalOpen,
  agents = [],
  accountConnections = [],
}: LinkNumberProps) => {
  const [equivalentCodeOpen, setEquivalentCodeOpen] = useState(false);
  const { environment } = useEnvironment();
  const envId = environment?.envId;
  const notification = useNotification();

  const [loading, setLoading] = useState(false);
  const [formNumber, setFormNumber] = useState<FormNumber>({
    telephony_provider: providers[0].value,
    phone_number: '',
    telephony_account_connection: '',
    inbound_agent: '',
  });

  const closeModal = () => {
    setModalOpen(false);
    setEquivalentCodeOpen(false);
    onClose();
  };

  const confirmLinkNumber = async () => {
    setLoading(true);

    if (
      !formNumber.telephony_account_connection ||
      formNumber.telephony_account_connection.length === 0
    ) {
      delete formNumber.telephony_account_connection;
    }

    const { inbound_agent, ...restFormNumber } = formNumber;

    await post(
      '/numbers/link',
      {
        ...restFormNumber,
        ...(inbound_agent.length && { inbound_agent: inbound_agent }),
      },
      { envId },
    );
    notification.success(`Number linked`);
    setModalOpen(false);
    refreshData();
  };

  const isValidForm = (form: FormNumber) => {
    return (
      !form.telephony_provider ||
      !form.phone_number ||
      !form.telephony_account_connection
    );
  };

  const disabledSubmit = () => {
    if (loading) return true;

    return isValidForm(formNumber);
  };

  const searchAgents = useCallback(
    async (name: string) => {
      const queryObject: QueryObject = {
        ...(name.length !== 0 && { filters: JSON.stringify({ name }) }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/agents?${query.toString()}`, {
        envId,
      });

      return data.items.map((agent: Agent) => ({
        value: agent.id,
        label: agent.name,
      }));
    },
    [envId],
  );

  return (
    <Modal
      title={`Link Number`}
      isOpen={modalOpen}
      onClose={() => closeModal()}
      actionButton={
        <Button
          color="default"
          onClick={() => confirmLinkNumber()}
          disabled={disabledSubmit()}
        >
          Link
        </Button>
      }
    >
      <div className="flex justify-end mb-1">
        <Button
          onClick={() =>
            equivalentCodeOpen
              ? setEquivalentCodeOpen(false)
              : setEquivalentCodeOpen(true)
          }
          className="flex items-center justify-center"
        >
          <Code className="mr-2" size={20} />
          Show equivalent code
        </Button>
      </div>

      <div className="flex justify-end italic text-sm">* Required fields</div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="telephony_provider"
            >
              Provider <span className="text-red-500">*</span>
            </label>

            <SelectBox
              options={providers}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) =>
                setFormNumber({ ...formNumber, telephony_provider: value })
              }
              className="min-w-[140px]"
              defaultValue={providers[0]}
              disabled={loading}
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone-number"
            >
              Phone Number <span className="text-red-500">*</span>
            </label>

            <input
              type="number"
              id="phone-number"
              placeholder="Enter phone number"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              onChange={(e) =>
                setFormNumber({
                  ...formNumber,
                  phone_number: e.target.value,
                })
              }
              value={formNumber.phone_number}
              disabled={loading}
            />
          </div>
        </div>

        <div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 required-field"
              htmlFor="account-connection"
            >
              Account Connection
            </label>

            <SelectBox
              options={accountConnections}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) =>
                setFormNumber({
                  ...formNumber,
                  telephony_account_connection: value,
                })
              }
              className="min-w-[140px]"
              defaultValue={accountConnections.find(
                (item) =>
                  item.value === formNumber.telephony_account_connection,
              )}
              disabled={loading}
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="inbound-agent"
            >
              Inbound Agent
            </label>
            <AutoCompleteSelectBox
              defaultValue={agents.find(
                (item) => item.value === formNumber.inbound_agent,
              )}
              fetchOptions={searchAgents}
              onChange={(value) =>
                setFormNumber({ ...formNumber, inbound_agent: value })
              }
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
              key={formNumber.inbound_agent}
            />
          </div>
        </div>
      </div>

      {equivalentCodeOpen && (
        <div className="mt-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="block text-gray-700 text-md font-bold mb-2">
              Equivalent Code
            </h3>

            <X
              className="cursor-pointer inline-block w-5 h-5"
              onClick={() => setEquivalentCodeOpen(false)}
            />
          </div>

          <EquivalentCode
            payload={{
              ...formNumber,
              phone_number: undefined,
            }}
            endpoint={`numbers/link`}
            method="POST"
          />
        </div>
      )}
    </Modal>
  );
};
