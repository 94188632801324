type BaseVoice = {
  id: string;
  user_id?: string;
  voice_id?: string;
  voice_name?: string;
  label?: string;
  description?: string;
};

export type AzureVoice = {
  type: 'voice_azure';
  pitch?: number;
  rate?: number;
};
type AzureVoiceDto = BaseVoice & AzureVoice;

type RimeVoice = {
  type: 'voice_rime';
  speed_alpha?: number;
  model_id?: 'mist' | 'v1';
  speaker?: string;
};
export type RimeVoiceDto = BaseVoice & RimeVoice;

type EleventLabsVoice = {
  type: 'voice_eleven_labs';
  stability?: number;
  similarity_boost?: number;
  api_key?: string;
  optimize_streaming_latency?: boolean;
  model_id?: 'mist' | 'v1';
};
type ElevenLabsVoiceDto = BaseVoice & EleventLabsVoice;

type PlayHtVoice = {
  type: 'voice_play_ht';
  api_user_id: string;
  api_key: string;
  version?: string;
  speed?: number;
  quality?: 'faster' | 'draft' | 'low' | 'medium' | 'high' | 'premium';
  temperature?: number;
  top_p?: number;
  text_guidance?: string;
  voice_guidance?: string;
};
type PlayHtVoiceDto = BaseVoice & PlayHtVoice;

type CartesiaVoice = {
  type: 'voice_cartesia';
  model?: string;
  speed?: number;
  emotion?: string[];
  url?: string;
  api_key?: string;
};
type CartesiaDto = BaseVoice & CartesiaVoice;

export type Voice =
  | AzureVoiceDto
  | RimeVoiceDto
  | ElevenLabsVoiceDto
  | PlayHtVoiceDto
  | CartesiaDto;

export type VoicePayload = {
  type: string;
  voice_name?: string;
  label?: string;
  description?: string;
  // Eleven Labs specific
  stability?: number;
  similarity_boost?: number;
  api_key?: string;
  optimize_streaming_latency?: boolean;
  // Azure specific
  rate?: number;
  pitch?: number;
  // Play HT specific
  quality?: 'faster' | 'draft' | 'low' | 'medium' | 'high' | 'premium';
  version?: string;
  temperature?: number;
  top_p?: number;
  text_guidance?: string;
  voice_guidance?: string;
  // Rime specific
  speed_alpha?: number;
  model_id?: 'mist' | 'v1' | string;
  // Cartesia specific
  model?: string;
  speed?: number;
  emotion?: string[];
  url?: string;
};

export const mapVoicePayload = (fetchVoice: Voice) => {
  switch (fetchVoice.type) {
    case 'voice_azure':
      return {
        type: fetchVoice.type,
        voice_name: fetchVoice.voice_name,
        pitch: fetchVoice.pitch,
        rate: fetchVoice.rate,
      };
    case 'voice_rime':
      return {
        type: fetchVoice.type,
        speaker: fetchVoice.speaker,
        speed_alpha: fetchVoice.speed_alpha,
        model_id: fetchVoice.model_id,
      };
    case 'voice_eleven_labs':
      return {
        type: fetchVoice.type,
        voice_id: fetchVoice.voice_id,
        stability: fetchVoice.stability,
        similarity_boost: fetchVoice.similarity_boost,
        api_key: fetchVoice.api_key,
        optimize_streaming_latency: fetchVoice.optimize_streaming_latency,
        model_id: fetchVoice.model_id,
      };
    case 'voice_play_ht':
      return {
        type: fetchVoice.type,
        voice_id: fetchVoice.voice_id,
        api_user_id: fetchVoice.api_user_id,
        api_key: fetchVoice.api_key,
        version: fetchVoice.version,
        speed: fetchVoice.speed,
        quality: fetchVoice.quality,
        temperature: fetchVoice.temperature,
        top_p: fetchVoice.top_p,
        text_guidance: fetchVoice.text_guidance,
        voice_guidance: fetchVoice.voice_guidance,
      };
    default:
      return {};
  }
};
