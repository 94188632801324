import { useLocalStorageState } from 'ahooks';
import { FirebaseError } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearCachedToken, get, post, renewToken } from 'src/api/requests';
import { UnloggedCard } from 'src/components/UnloggedCard';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { getFirebaseErrorMessage } from 'src/utils/FirebaseErrorUtils';
import { Button } from '../components/Button';
import { AxiosError } from 'axios';

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [, setFireBaseDisplayName] = useLocalStorageState<string | null>(
    '@control-plane/fireBaseDisplayName',
    {
      defaultValue: '',
      listenStorageChange: true,
    },
  );
  const [, setIsClosedAlphaVisible] = useLocalStorageState<boolean>(
    '@control-plane/closed-alpha-visible',
    {
      defaultValue: false,
      listenStorageChange: true,
    },
  );

  const notification = useNotification();
  const { environment, updateEnvironment } = useEnvironment();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const user = userCredential.user;

      // Check if email is verified
      if (!user?.emailVerified) {
        await sendEmailVerification(user);

        notification.error(
          'Email is not verified! Verification email has been resent.',
        );
        auth.signOut();
        setLoading(false);
        return;
      }

      setFireBaseDisplayName(user.displayName);

      if (user) {
        const [fname, lname] = user?.displayName?.split(' ') || [];

        try {
          await post(`/users`, {
            fname,
            lname,
            email,
          });
          await renewToken();
        } catch (error) {
          if (error instanceof Error) {
            if (error?.message !== 'User found in the system') {
              notification.error(error.message);
              return;
            }
          }
          const err = error as AxiosError;
          if (err.status === 403) {
            notification.error(JSON.parse(err.request.response).message);
            return;
          }
        }

        const environments = await get('/environments');
        const isStoredEnvInEnvironments = environments.find(
          (env: { id: string }) => env.id === environment?.envId,
        );

        const initialEnvId =
          environment?.envId &&
          environment.userId === user.uid &&
          isStoredEnvInEnvironments
            ? environment.envId
            : environments[0].id;

        updateEnvironment({
          userId: user.uid,
          envId: initialEnvId,
        });

        await post(
          `/users/sync`,
          {},
          {
            envId: initialEnvId,
          },
        );

        clearCachedToken();

        setLoading(true);

        // sleep 2 seconds to make sure the environment is updated
        await new Promise((resolve) => setTimeout(resolve, 2000));

        navigate('/');
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        notification.error(getFirebaseErrorMessage(error.code));
      } else {
        notification.error('Error during login');
      }
    }

    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);

      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      setFireBaseDisplayName(user.displayName);

      if (user) {
        const [fname, lname] = user?.displayName?.split(' ') || [];
        const googleEmail = user.email || '';

        try {
          await post(`/users`, {
            fname,
            lname,
            email: googleEmail,
          });
          await renewToken();
        } catch (error) {
          if (error instanceof Error) {
            if (error?.message !== 'User found in the system') {
              notification.error(error.message);
              return;
            }
          }
          const err = error as AxiosError;
          if (err.status === 403) {
            notification.error(JSON.parse(err.request.response).message);
            return;
          }
        }

        const environments = await get('/environments');
        const isStoredEnvInEnvironments = environments.find(
          (env: { id: string }) => env.id === environment?.envId,
        );

        const initialEnvId =
          environment?.envId &&
          environment.userId === user.uid &&
          isStoredEnvInEnvironments
            ? environment.envId
            : environments[0].id;

        updateEnvironment({
          userId: user.uid,
          envId: initialEnvId,
        });

        await post(
          `/users/sync`,
          {},
          {
            envId: initialEnvId,
          },
        );

        clearCachedToken();

        notification.success('Login with Google successful!');

        // sleep 2 seconds to make sure the environment is updated
        await new Promise((resolve) => setTimeout(resolve, 2000));

        navigate('/');
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        notification.error(getFirebaseErrorMessage(error.code));
      } else {
        notification.error('Error during Google login');
      }
    }
  };

  useEffect(() => {
    setIsClosedAlphaVisible(false);
  }, []);

  return (
    <UnloggedCard>
      <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Password
          </label>
          <input
            type="password"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <Button className="w-full mb-6" type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Sign In'}
        </Button>

        <Button
          className="w-full mb-6"
          color="info"
          onClick={handleGoogleSignIn}
          disabled={loading}
        >
          <img className="inline mr-2 h-6" src="/assets/google-g.png" />
          Sign in with Google
        </Button>

        <div className="relative mb-6">
          <div className="flex items-center justify-center">
            <div className="w-full border-t border-gray-300"></div>
            <span className="absolute bg-white px-2 text-gray-600">or</span>
          </div>
        </div>

        <Button
          className="w-full mb-2"
          onClick={() => navigate('/signup')}
          disabled={loading}
        >
          Sign Up
        </Button>

        <Button
          className="w-full"
          onClick={() => navigate('/reset-password')}
          disabled={loading}
        >
          Reset Password
        </Button>
      </form>
    </UnloggedCard>
  );
};
