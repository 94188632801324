import { AccountConnection } from 'src/interfaces/accountConnection.interface';
import { AzureVoice, Voice } from 'src/interfaces/voice.interface';
import { post } from './requests';

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

const playTTS = async (
  voice: Voice,
  text: string,
  envId: string,
  provider?: AccountConnection,
) => {
  try {
    let response;

    switch (voice.type) {
      case 'voice_azure':
        if (!provider?.credentials.region || !provider?.credentials.api_key) {
          throw new Error('Azure credentials missing');
        }
        response = await fetch(
          `https://${provider.credentials.region}.tts.speech.microsoft.com/cognitiveservices/v1`,
          {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': provider.credentials.api_key,
              'Content-Type': 'application/ssml+xml',
              'X-Microsoft-OutputFormat': 'audio-16khz-128kbitrate-mono-mp3',
            },
            body: `<speak version='1.0' xml:lang='en-US'>
              <voice xml:lang='en-US' name='${voice.voice_name}'>
                <prosody rate='${(voice as AzureVoice).rate || 0}%' pitch='${(voice as AzureVoice).pitch || 0}%'>
                  ${text}
                </prosody>
              </voice>
            </speak>`,
          },
        );
        break;

      case 'voice_eleven_labs':
        response = await fetch(
          `https://api.elevenlabs.io/v1/text-to-speech/${voice.voice_id}`,
          {
            method: 'POST',
            headers: {
              'xi-api-key': provider?.credentials.api_key || '',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              text,
              model_id: voice.model_id,
              stability: voice.stability,
              similarity_boost: voice.similarity_boost,
              optimize_streaming_latency: voice.optimize_streaming_latency,
            }),
          },
        );
        break;

      case 'voice_cartesia':
        response = await fetch('https://api.cartesia.ai/tts/bytes', {
          method: 'POST',
          headers: {
            'Cartesia-Version': '2024-06-10',
            'X-API-Key': provider?.credentials.api_key || '',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model_id: voice.model || 'sonic-english',
            transcript: text,
            voice: {
              mode: 'id',
              id: voice.voice_id,
            },
            output_format: {
              container: 'mp3',
              bit_rate: 128000,
              sample_rate: 44100,
            },
            language: 'en',
          }),
        });
        break;

      case 'voice_play_ht': {
        const responsePlayht = await post(
          '/voices/playht/listen',
          {
            text,
            voice: voice.voice_id,
            voice_engine: 'PlayDialog',
            output_format: 'mp3',
          },
          {
            envId,
            headers: {
              'playht-api-key': provider?.credentials.api_key || '',
              'playht-user-id': provider?.credentials.userId || '',
              'Content-Type': 'application/json',
              accept: 'audio/mpeg',
            },
          },
        );

        const uint8Array = new Uint8Array(responsePlayht.data as ArrayBuffer);
        const audioBlob = new Blob([uint8Array], {
          type: 'audio/mpeg',
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        audio.addEventListener('ended', () => {
          URL.revokeObjectURL(audioUrl);
        });

        await audio.play();
        return true;
      }

      default:
        throw new Error('Unsupported voice type');
    }

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    const audioBlob = await response.blob();
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = new Audio(audioUrl);
    await audio.play();

    audio.addEventListener('ended', () => {
      URL.revokeObjectURL(audioUrl);
    });

    return true;
  } catch (error) {
    console.error('TTS Error:', error);
    return false;
  }
};

export const listenVoice = async (
  voice: Voice,
  text: string,
  envId: string,
  provider?: AccountConnection,
) => {
  return await playTTS(voice, text, envId, provider);
};
