import { ReactNode } from 'react';

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  zIndex?: 10 | 20 | 30 | 40 | 50;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  position = 'top',
  zIndex = 10,
}) => {
  const positionClasses = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 translate-y-2',
    bottom: 'top-full left-1/2 transform -translate-x-1/2 -translate-y-2',
    left: 'right-full top-1/2 transform -translate-y-1/2 -translate-x-2',
    right: 'left-full top-1/2 transform -translate-y-1/2 translate-x-2',
  };

  const arrowPositionClasses = {
    top: 'bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1',
    bottom: 'top-0 left-1/2 transform -translate-x-1/2 -translate-y-1',
    left: 'right-0 top-1/2 transform -translate-y-1/2 translate-x-1',
    right: 'left-0 top-1/2 transform -translate-y-1/2 -translate-x-1',
  };

  const isDisabled = content === '';

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <div className="relative group inline-block">
      {children}

      <div
        role="tooltip"
        className={`absolute z-${zIndex} ${positionClasses[position]} invisible opacity-0 group-hover:visible group-hover:opacity-100 bg-tooltip-background text-tooltip-color text-xs rounded py-2 px-3 whitespace-nowrap transition-opacity duration-300`}
      >
        {content}
        <div
          className={`absolute w-2 h-2 bg-tooltip-background transform rotate-45 ${arrowPositionClasses[position]}`}
        />
      </div>
    </div>
  );
};
