import {
  ArrowSquareOut,
  MagnifyingGlass,
  Pencil,
  Trash,
  UserSound,
} from '@phosphor-icons/react';
import * as Sentry from '@sentry/browser';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, post, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { Heading } from 'src/components/Heading';
import { Modal } from 'src/components/Modal';
import { SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Agent } from 'src/interfaces/agent.interface';
import { Number } from 'src/interfaces/number.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { mapVoicePayload, Voice } from 'src/interfaces/voice.interface';
import { Webhook } from 'src/interfaces/webhook.interface';
import { getFirstAndLastFourUUID, processPhoneNumber } from 'src/utils/number';

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'name', label: 'Name', width: '50%' },
  { key: 'action', label: 'Action', width: '30%', disableSorting: true },
];

export const ListAgents = () => {
  const notification = useNotification();
  const { environment, environmentList } = useEnvironment();
  const envId = environment?.envId;

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [modalOpenDuplication, setModalOpenDuplication] = useState(false);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentAgent, setCurrentAgent] = useState<Agent | null>(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState<string | null>(
    String(envId),
  );
  const [callerNumber, setCallerNumber] = useState('');
  const [receiverNumber, setReceiverNumber] = useState('');
  const [numbers, setNumbers] = useState<Number[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      ...(filter.length !== 0 && { filters: JSON.stringify({ name: filter }) }),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/agents?${query.toString()}`, {
      envId,
    });

    const items = data.items.map((item: Agent) =>
      Object({
        ...item,
        id: (
          <CopyableField value={String(item.id)} notification={notification} />
        ),
        name:
          item.name || item.label || getFirstAndLastFourUUID(String(item.id)),
        action: (
          <div className="flex">
            {item.user_id && (
              <Button
                className="ml-2"
                onClick={() => navigate('/agent/edit/' + item.id)}
              >
                <Pencil className="cursor-pointer inline-block w-5 h-5" />
              </Button>
            )}

            <Tooltip content="Duplicate agent" position="top">
              <Button
                className="ml-2"
                onClick={() => openModalDuplicateAgent(item)}
                color="secondary"
              >
                <UserSound className="cursor-pointer inline-block w-5 h-5" />
              </Button>
            </Tooltip>

            <Button
              className="ml-2"
              variant="outlined"
              onClick={() => openModalOutboundCall(item)}
            >
              Test
            </Button>

            {item.user_id && (
              <Button
                className="ml-2"
                onClick={() => openDeleteAgent(item)}
                color="error"
              >
                <Trash className="cursor-pointer inline-block w-5 h-5" />
              </Button>
            )}
          </div>
        ),
      }),
    );

    setRows(items);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, sortConfig, debouncedFilter, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    handlePageChange(1, size);
  };

  const openModalOutboundCall = (agent: Agent) => {
    setCurrentAgent(agent);
    setModalOpen(true);
  };

  const closeModalOutboundCall = () => {
    setModalOpen(false);
  };

  const fetchNumbers = async () => {
    try {
      const data = await get(`/numbers`, { envId });
      setNumbers(data.items);
    } catch (error) {
      console.error('Failed to fetch numbers:', error);
    }
  };

  const makeCall = async () => {
    if (!currentAgent) {
      notification.error('No agent selected');
      return;
    }

    const data = {
      from_number: callerNumber,
      to_number: receiverNumber,
      agent: currentAgent?.id,
      on_no_human_answer: 'continue',
      run_do_not_call_detection: true,
      hipaa_compliant: false,
      context: {},
      telephony_params: {},
    };

    try {
      setLoading(true);

      await post('/calls', data, {
        envId,
      });

      notification.success('Call has been made successfully');
      setModalOpen(false);
      setLoading(false);
    } catch {
      notification.error('Failed to make call');
      setLoading(false);
    }
  };

  const openModalDuplicateAgent = async (agent: Agent) => {
    setCurrentAgent(agent);
    setModalOpenDuplication(true);
  };

  const closeModalDuplicateAgent = () => {
    setCurrentAgent(null);
    setSelectedEnvironment(String(envId));
    setModalOpenDuplication(false);
  };

  const duplicateAgentIntoEnvironment = async () => {
    if (!currentAgent || !selectedEnvironment) {
      notification.error(
        `No ${!currentAgent ? 'agent' : 'environment'} selected`,
      );
      return;
    }

    setLoading(true);

    try {
      let voiceToUse = {
        id: currentAgent.voice,
      } as Voice;

      let promptToUse = {
        id: currentAgent.prompt,
      } as Prompt;

      let webhookToUse: Webhook | undefined = {
        id: currentAgent.webhook,
      } as Webhook;

      let actionsToUse = currentAgent.actions as string[];

      if (envId !== selectedEnvironment) {
        const fetchPrompt = await get(`/prompts/${currentAgent.prompt}`, {
          envId,
        });
        if (!fetchPrompt) throw new Error('Failed to fetch prompt');

        promptToUse = { content: fetchPrompt.content } as Prompt;

        const fetchVoice = await get(`/voices/${currentAgent.voice}`, {
          envId,
        });
        if (!fetchVoice) throw new Error('Failed to fetch voice');

        voiceToUse = mapVoicePayload(fetchVoice) as Voice;

        if (currentAgent.webhook) {
          const fetchWebhook = await get(`/webhooks/${currentAgent.webhook}`, {
            envId,
          });
          if (fetchWebhook) {
            const webhookToSave = { ...fetchWebhook, id: undefined };
            const createWebhook = await post('/webhooks', webhookToSave, {
              envId: selectedEnvironment,
            });
            webhookToUse = createWebhook as Webhook;
          }
        }

        const actionsFetched = await Promise.all(
          actionsToUse.map(async (actionId) => {
            const fetchAction = await get(`/actions/${actionId}`, {
              envId,
            });
            if (!fetchAction) throw new Error('Failed to fetch action');
            return fetchAction;
          }),
        );
        const actionsToSave = actionsFetched.map((action) => ({
          ...action,
          id: undefined,
        }));
        const actionsCreated = await Promise.all(
          actionsToSave.map((action) =>
            post(
              '/actions',
              { ...action, id: undefined },
              { envId: selectedEnvironment },
            ),
          ),
        );

        const newActions: string[] = actionsCreated.map(
          (createAction) => createAction.id,
        );

        actionsToUse = newActions;
      }

      const clonedAgent = {
        ...currentAgent,
        name: `${currentAgent.name} - Copy`,
        prompt: promptToUse.id || promptToUse,
        voice: voiceToUse.id || voiceToUse,
        ...(webhookToUse && { webhook: webhookToUse.id || webhookToUse }),
        actions: actionsToUse,
        user_id: undefined,
        id: undefined,
      };

      await post('/agents', clonedAgent, { envId: selectedEnvironment });

      if (envId === selectedEnvironment) {
        fetchData(currentPage, size);
      }

      notification.success('Agent duplicated successfully');
      setModalOpenDuplication(false);
    } catch (error) {
      notification.error('Failed to duplicate agent');
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteAgent = (agent: Agent) => {
    setModalDeleteOpen(true);
    setSelectedId(String(agent.id));
  };

  const handleRemoveAgent = async () => {
    setLoading(true);
    try {
      await remove(`/agents/${selectedId}`, { envId });
      notification.success('Agent deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete agent');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNumbers();
  }, []);

  return (
    <div className="flex-1">
      <Heading
        title="Agents"
        subtitle="Agents define the behavior of your bot and are made out of prompts and voices. You can set them up here."
      >
        <div className="flex mt-6">
          <Button className="w-40" onClick={() => navigate('/agent/new')}>
            Create Agent
          </Button>

          <Button
            className="ml-4 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/agents"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            required
            placeholder="Search agents"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={submitFilter}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={rows}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />

        <Modal
          title={`Test Agent - ${currentAgent?.name}`}
          isOpen={modalOpen}
          onClose={() => closeModalOutboundCall()}
          actionButton={
            <Button
              color="default"
              disabled={loading || !callerNumber || !receiverNumber}
              onClick={makeCall}
            >
              Make Call
            </Button>
          }
        >
          <div className="flex justify-end italic text-sm mt-4">
            * Required fields
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="provider"
                >
                  Caller Number
                </label>

                <SelectBox
                  options={numbers
                    .filter((n) => n.active === true)
                    .map((n) => ({
                      label: processPhoneNumber(n.number),
                      value: n.number,
                    }))}
                  defaultValue={{
                    label: 'Select a number',
                    value: '',
                  }}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  className="min-w-[140px]"
                  onChange={(value) => setCallerNumber(value)}
                  disabled={loading}
                />
              </div>
            </div>

            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required-field"
                  htmlFor="receiver-number"
                >
                  Receiver Number
                </label>

                <input
                  type="text"
                  id="receiver-number"
                  placeholder="Enter receiver number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  value={receiverNumber}
                  onChange={(e) => setReceiverNumber(e.target.value)}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </Modal>

        {/* Duplicate Agent Modal */}
        <Modal
          title={`Duplicate Agent - ${currentAgent?.name}`}
          isOpen={modalOpenDuplication}
          onClose={() => closeModalDuplicateAgent()}
          actionButton={
            <Button
              color="default"
              disabled={
                loading || currentAgent === null || !selectedEnvironment
              }
              onClick={() =>
                currentAgent ? duplicateAgentIntoEnvironment() : null
              }
            >
              Duplicate
            </Button>
          }
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="environment"
                >
                  Environment
                </label>

                <SelectBox
                  key={selectedEnvironment}
                  options={environmentList.map((env) => ({
                    label: env.name,
                    value: env.id,
                  }))}
                  defaultValue={{
                    label: environmentList.find(
                      (env) => env.id === selectedEnvironment,
                    )?.name,
                    value: String(selectedEnvironment),
                  }}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  className="min-w-[140px]"
                  onChange={(value) => setSelectedEnvironment(value)}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          title="Delete Agent"
          isOpen={modalDeleteOpen}
          onClose={() => {
            setModalDeleteOpen(false);
            setSelectedId('');
          }}
          actionButton={
            <Button color="default" onClick={handleRemoveAgent}>
              Delete Agent
            </Button>
          }
          className="w-96"
        >
          <div className="text-center">
            Are you sure you want to delete this agent?
          </div>
        </Modal>
      </ContentWrapper>
    </div>
  );
};
