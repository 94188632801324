import { getAuth } from 'firebase/auth';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { settings } from '../constants';

let cachedToken: string | null = null;
let tokenExpirationTime: number | null = null;

type LimitError = {
  error: string;
  message: string;
};

const onSuccess = (response: AxiosResponse) => response.data;

const onError = (error: AxiosError) => {
  if (error.response) {
    const statusCode = error.response.status;

    if (statusCode >= 400 && statusCode < 500) {
      console.warn('Client error:', statusCode);
    } else if (statusCode >= 500) {
      console.error('Server error:', statusCode);
    }

    if ((error.response.data as LimitError).error === 'LimitError') {
      window.location.href = '/billing?error=LimitError';
    }
  } else if (error.request) {
    console.error('Network or server unreachable:', error.message);
  } else {
    console.error('Unexpected error:', error.message);
  }

  return Promise.reject(error.response || error.message);
};

const getToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.warn('User not authenticated, redirecting to login');
    window.location.href = '/login';
    throw new Error('User not authenticated');
  }

  const currentTime = Date.now() / 1000;
  if (cachedToken && tokenExpirationTime && currentTime < tokenExpirationTime) {
    return cachedToken;
  }

  try {
    const idTokenResult = await user.getIdTokenResult(true);
    cachedToken = idTokenResult.token;
    tokenExpirationTime = idTokenResult.expirationTime
      ? new Date(idTokenResult.expirationTime).getTime() / 1000
      : null;
    return cachedToken;
  } catch {
    window.location.href = '/login';
  }
};

export const renewToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    const idTokenResult = await user.getIdTokenResult(true);
    cachedToken = idTokenResult.token;
  }
};

export const clearCachedToken = () => {
  cachedToken = null;
  tokenExpirationTime = null;
};

export const request = async (
  url: string,
  options?: AxiosRequestConfig & { envId?: string; withoutToken?: boolean },
) => {
  try {
    const token = options?.withoutToken ? null : await getToken();
    const { headers, ...optionsWithoutHeaders } = options || {};

    const config: AxiosRequestConfig = {
      baseURL: settings.baseApiUrl,
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        'Content-type': 'application/json',
        'x-env': options?.envId || undefined,
        ...headers,
      },
      ...optionsWithoutHeaders,
      url,
    };

    return axios.request(config).then(onSuccess).catch(onError);
  } catch (error) {
    console.error('Error making request:', error);
    return Promise.reject(error);
  }
};

export const get = (
  url: string,
  options?: AxiosRequestConfig & { envId?: string },
) =>
  request(url, {
    ...options,
    method: 'GET',
  });

export const post = (
  url: string,
  data: object,
  options?: AxiosRequestConfig & { envId?: string; withoutToken?: boolean },
) =>
  request(url, {
    ...options,
    method: 'POST',
    data,
  });

export const patch = (
  url: string,
  data: object,
  options?: AxiosRequestConfig & { envId?: string },
) =>
  request(url, {
    ...options,
    method: 'PATCH',
    data,
  });

export const put = (
  url: string,
  data: object,
  options?: AxiosRequestConfig & { envId?: string },
) =>
  request(url, {
    ...options,
    method: 'PUT',
    data,
  });

export const remove = (
  url: string,
  options?: AxiosRequestConfig & { envId?: string },
) =>
  request(url, {
    ...options,
    method: 'DELETE',
  });
