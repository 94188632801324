import { X } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';

export default function RightHandSidebar() {
  const location = useLocation();
  const { isOpen, closeSidebar, content, title } = useRightHandSidebar();

  useEffect(() => {
    setTimeout(() => {
      closeSidebar();
    }, 100);
  }, [location.pathname]);

  return (
    <div
      className={clsx(
        'bg-white rounded-r-3xl border-l-2 my-4 transition-all duration-300 ease-in-out overflow-hidden mr-4',
        isOpen ? 'block' : 'hidden',
      )}
    >
      <div
        className={clsx(
          'flex items-center p-2 border-b',
          title
            ? 'justify-between border-gray-200'
            : 'justify-end border-transparent h-10',
        )}
      >
        {title && (
          <h2 className="text-xl font-bold text-primary-title mx-3 my-2">
            {title}
          </h2>
        )}

        <button
          onClick={closeSidebar}
          className={clsx(
            'text-primary-title hover:text-gray-700',
            'focus:outline-none mr-2',
          )}
        >
          <X size={20} />
        </button>
      </div>

      <div className={clsx('p-4', !title && 'pt-0', 'overflow-auto h-full')}>
        {content ? <>{content}</> : <p>No data available.</p>}
      </div>
    </div>
  );
}
