const getPayloadJWT = (str) => {
  try {
    if (!str) throw new Error('Invalid token');
    const parts = str.split('.');
    if (parts.length !== 3) throw new Error('Invalid token format');

    const payload = parts[1].replace(/-/g, '+').replace(/_/g, '/');
    const paddedPayload = payload + '='.repeat((4 - (payload.length % 4)) % 4);
    const decoded = atob(paddedPayload);

    return JSON.parse(decoded);
  } catch (e) {
    throw new Error('Failed to decode JWT: ' + e.message);
  }
};

const isJWT = (str) => {
  if (!str || typeof str !== 'string') return false;

  const parts = str.split('.');
  if (parts.length !== 3) return false;

  try {
    for (const part of parts) {
      const cleaned = part.replace(/-/g, '+').replace(/_/g, '/');
      const padded = cleaned + '='.repeat((4 - (cleaned.length % 4)) % 4);
      atob(padded);
    }
    return true;
  } catch {
    return false;
  }
};

export { getPayloadJWT, isJWT };
