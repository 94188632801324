import { Copy, Eye, EyeClosed, Pencil, Trash } from '@phosphor-icons/react';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { patch, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useNotification } from 'src/contexts/NotificationContext';
import { Environment } from 'src/interfaces/environment.interface';
import { obfuscateToken } from 'src/utils/token';

const apiKeyHeaders = [
  { key: 'name', label: 'Environment Name', width: '20%' },
  { key: 'deploymentKeyElement', label: 'API Key', width: '40%' },
  { key: 'createdAt', label: 'Created At', width: '20%' },
  { key: 'actions', label: 'Actions', width: '20%', disableSorting: true },
];

interface ApiKeysProps {
  envId: string;
  environmentList: Environment[];
  fetchEnvironments: (page: number, perPage: number) => Promise<void>;
}

export const ApiKeys = ({
  envId,
  environmentList,
  fetchEnvironments,
}: ApiKeysProps) => {
  const notification = useNotification();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState<Environment | null>(
    {} as Environment,
  );

  const [providersKeyObfuscated, setProvidersKeyObfuscated] = useState<{
    [key: string]: boolean;
  }>({});
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [, setApiKeySortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);

  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState('');

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    await fetchEnvironments(page, perPage);

    setTotalItems(environmentList.length);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage]);

  useEffect(() => {
    setLoading(true);

    for (const item of environmentList) {
      providersKeyObfuscated[item.id] = true;
    }

    setProvidersKeyObfuscated(providersKeyObfuscated);
    setLoading(false);
  }, [environmentList]);

  const toggleProvidersKeyObfuscated = (key: string) => {
    setProvidersKeyObfuscated({
      ...providersKeyObfuscated,
      [key]: !providersKeyObfuscated[key],
    });
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    notification.success('Copied to clipboard');
  };

  const openEditModal = (apiKey: Environment) => {
    setSelectedApiKey(apiKey);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedApiKey({} as Environment);
    setModalOpen(false);
  };

  const submitModal = async () => {
    if (!selectedApiKey) return;

    setLoading(true);

    await patch(
      `/environments/${selectedApiKey.id}`,
      {
        name: selectedApiKey.name,
      },
      {
        envId,
      },
    );

    fetchData(currentPage, size);
    closeModal();
    notification.success('Environment updated successfully');

    setLoading(false);
  };

  const openDeleteModal = (id: string) => {
    setModalDeleteOpen(true);
    setSelectedDeleteId(id);
  };

  const deleteApiKey = async () => {
    setLoading(true);

    try {
      await remove(`/environments/${selectedDeleteId}`, {
        envId,
      });

      notification.success('Environment deleted successfully');
      await fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete API Key');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-6">
      <h2 className="text-xl font-semibold text-control-plane-400 mb-4">
        API Keys
      </h2>

      <Table
        headers={apiKeyHeaders}
        rows={environmentList.map((item) => ({
          ...item,
          createdAt: format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss'),
          organization: item.organization.name,
          deploymentKeyElement: (
            <div>
              <span
                className="cursor-pointer"
                onClick={() => toggleProvidersKeyObfuscated(item.id)}
              >
                {providersKeyObfuscated[item.id]
                  ? obfuscateToken(item.deploymentKey)
                  : item.deploymentKey}
                {providersKeyObfuscated[item.id] ? (
                  <Eye className="inline-block w-5 h-5" />
                ) : (
                  <EyeClosed className="inline-block w-5 h-5" />
                )}
              </span>

              <Tooltip content="Copy to clipboard" position="top">
                <Copy
                  className="cursor-pointer inline-block w-5 h-5 ml-2"
                  onClick={() => copyToClipboard(item.deploymentKey)}
                />
              </Tooltip>
            </div>
          ),
          actions: (
            <div>
              <Button
                variant="contained"
                className="ml-2"
                onClick={() => openEditModal(item)}
              >
                <Pencil className="cursor-pointer inline-block w-5 h-5" />
              </Button>

              <Button
                variant="contained"
                className="ml-2"
                onClick={() => openDeleteModal(item.id)}
                color="error"
              >
                <Trash className="cursor-pointer inline-block w-5 h-5" />
              </Button>
            </div>
          ),
        }))}
        totalItems={totalItems}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        loading={loading || Object.keys(providersKeyObfuscated).length === 0}
        setSize={setSize}
        defaultSize={size}
        onSort={setApiKeySortConfig}
      />

      <Modal
        title={`Edit API Key`}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        actionButton={
          <Button
            color="default"
            onClick={() => submitModal()}
            disabled={loading || !selectedApiKey || selectedApiKey.name === ''}
          >
            Save API Key
          </Button>
        }
      >
        <div className="flex justify-end italic text-sm">* Required fields</div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 required-field"
                htmlFor="environment-name"
              >
                Environment Name
              </label>

              <input
                type="text"
                id="environment-name"
                placeholder="Enter voice name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                onChange={(e) => {
                  if (!selectedApiKey) return;

                  setSelectedApiKey({
                    ...selectedApiKey,
                    name: e.target.value,
                  });
                }}
                value={selectedApiKey?.name}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Delete API Key"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedDeleteId('');
        }}
        actionButton={
          <Button color="default" onClick={deleteApiKey} disabled={loading}>
            Delete API Key
          </Button>
        }
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this environment?
        </div>
      </Modal>
    </div>
  );
};
