import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import {
  CaretDown,
  Code,
  Handshake,
  Headset,
  Money,
} from '@phosphor-icons/react';
import _ from 'lodash';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'src/api/requests';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Button } from 'src/components/Button';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { MultiSelectBox } from 'src/components/MultiSelectBox';
import { SelectBox } from 'src/components/SelectBox';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { Action } from 'src/interfaces/action.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { Webhook } from 'src/interfaces/webhook.interface';
import { limitString } from 'src/utils/limitString';
import { getFirstAndLastFourUUID } from 'src/utils/number';
import {
  AgentStepProps,
  Language,
  LLMProvider,
} from '../../../interfaces/agent.interface';
import { AGENT_TEMPLATES } from '../constants';

const TEMPLATE_ICONS: { [key: string]: ReactNode } = {
  customer_support: <Headset />,
  debt_relief: <Money />,
  sales: <Handshake />,
};

const languages = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'de', label: 'German' },
  { value: 'hi', label: 'Hindi' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'fr', label: 'French' },
  { value: 'nl', label: 'Dutch' },
  { value: 'id', label: 'Indonesian' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
];

const llmProviders = [
  { value: 'openai', label: 'OpenAI' },
  { value: 'google', label: 'Google' },
  { value: 'azure', label: 'Azure OpenAI' },
];

const providerModels = {
  openai: [
    { value: 'gpt-4', label: 'GPT-4' },
    { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
    { value: 'gpt-4o', label: 'GPT-4o' },
    { value: 'gpt-4o-mini', label: 'GPT-4o Mini' },
    { value: 'gpt-3.5', label: 'GPT-3.5' },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
  ],
  google: [
    { value: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro' },
    { value: 'gemini-1.5-flash', label: 'Gemini 1.5 Flash' },
    { value: 'gemini-2.0-flash-exp', label: 'Gemini 2.0 Flash Exp' },
  ],
  azure: [{ value: 'gpt-4o-mini', label: 'GPT-4o Mini' }],
};

export const AgentSingleStep = ({
  agent,
  setAgent,
  loading = false,
  defaultPrompt,
  defaultVoice,
  defaultWebhook,
  defaultActions = [],
  actions = [],
}: AgentStepProps & {
  defaultPrompt?: Prompt;
  defaultVoice?: Voice;
  defaultWebhook?: Webhook;
  defaultActions?: Action[];
  actions: Action[];
}) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const [promptContent, setPromptContent] = useState('');

  const defaultActionList = defaultActions.map((action) => ({
    value: action.id,
    label: `${action.type} (${getFirstAndLastFourUUID(action.id)})`,
  }));

  const actionList = _.uniqBy(
    [
      ...defaultActionList,
      ...actions.map((action) => ({
        value: action.id,
        label: `${action.type} (${getFirstAndLastFourUUID(action.id)})`,
      })),
    ],
    'value',
  );

  const handlePromptEdit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromptContent(event.target.value);
  };

  const endpoint = agent.id ? `agents/update?id=${agent.id}` : 'agents/create';

  const handleAgentEquivalentCode = () => {
    openSidebar(
      <EquivalentCode payload={agent} endpoint={endpoint} method="POST" />,
      'Equivalent Code',
    );
  };

  const searchPrompts = useCallback(
    async (content: string) => {
      const queryObject: QueryObject = {
        ...(content.length !== 0 && { filters: JSON.stringify({ content }) }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/prompts?${query.toString()}`, {
        envId,
      });

      return data.items.map((prompt: Prompt) => ({
        value: prompt.id,
        label: limitString(prompt.content, 50),
      }));
    },
    [envId],
  );

  const searchVoices = useCallback(
    async (label: string) => {
      const queryObject: QueryObject = {
        ...(label.length !== 0 && {
          filters: JSON.stringify({ label }),
        }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/voices?${query.toString()}`, {
        envId,
      });

      return data.items.map((voice: Voice) => ({
        value: voice.id,
        label: voice.label,
      }));
    },
    [envId],
  );

  const searchWebhooks = useCallback(
    async (url: string) => {
      const queryObject: QueryObject = {
        ...(url.length !== 0 && { filters: JSON.stringify({ url }) }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/webhooks?${query.toString()}`, {
        envId,
      });

      return data.items.map((webhook: Webhook) => ({
        value: webhook.id,
        label: webhook.url,
      }));
    },
    [envId],
  );

  useEffect(() => {
    async function populatePromptContent() {
      if (typeof agent?.prompt === 'string') {
        const prompt = await get(`/prompts/${agent.prompt}`, {
          envId,
        });
        setPromptContent(prompt.content);
      }
      if (typeof agent?.prompt === 'object') {
        setPromptContent(agent.prompt.content);
      }
    }

    populatePromptContent();
  }, [agent?.prompt]);

  useEffect(() => {
    if (promptContent !== defaultPrompt?.content) {
      setAgent({ ...agent, prompt: { content: promptContent } });
    }
  }, [promptContent]);

  useEffect(() => {
    if (isOpen && !loading) {
      handleAgentEquivalentCode();
    }
  }, [agent, isOpen]);

  return (
    <div>
      <div className="flex justify-end mb-1">
        <Button
          onClick={() =>
            isOpen ? closeSidebar() : handleAgentEquivalentCode()
          }
          className="flex items-center justify-center"
        >
          <Code className="mr-2" size={20} />
          Show equivalent code
        </Button>
      </div>

      <div className="flex justify-end italic text-sm mt-4">
        * Required fields
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <div className="mb-3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="agent-name"
            >
              Agent Name
            </label>

            <input
              type="text"
              id="agent-name"
              placeholder="Enter agent name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              value={agent.name}
              onChange={(e) => setAgent({ ...agent, name: e.target.value })}
              disabled={loading}
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="initialMessage"
          >
            Initial Message
          </label>

          <input
            type="text"
            id="initialMessage"
            placeholder="Hi {name}, this is Fluents AI. How can I help you today?"
            value={agent.initial_message}
            onChange={(e) =>
              setAgent({ ...agent, initial_message: e.target.value })
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
          />
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 required-field"
            htmlFor="voice"
          >
            Voice
          </label>

          <AutoCompleteSelectBox
            key={defaultVoice?.id}
            defaultValue={
              defaultVoice?.id
                ? {
                    value: defaultVoice.id,
                    label: defaultVoice.label,
                  }
                : undefined
            }
            fetchOptions={searchVoices}
            onChange={(value) => {
              setAgent({ ...agent, voice: value });
            }}
            className="min-w-[140px]"
            variant="contained"
            color="primary"
            size="medium"
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="prompt"
          >
            Prompt Library
          </label>

          <AutoCompleteSelectBox
            key={defaultPrompt?.id}
            defaultValue={
              defaultPrompt?.id
                ? {
                    value: defaultPrompt.id,
                    label: limitString(defaultPrompt.content, 50),
                  }
                : undefined
            }
            fetchOptions={searchPrompts}
            onChange={(value) => {
              setAgent({ ...agent, prompt: value });
            }}
            className="min-w-[140px]"
            variant="contained"
            color="primary"
            size="medium"
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="prompt"
          >
            Webhooks
          </label>

          <AutoCompleteSelectBox
            key={defaultWebhook?.id}
            defaultValue={
              defaultWebhook?.id
                ? {
                    value: defaultWebhook.id,
                    label: defaultWebhook.url,
                  }
                : undefined
            }
            fetchOptions={searchWebhooks}
            onChange={(value) => {
              setAgent({ ...agent, webhook: value });
            }}
            className="min-w-[140px]"
            variant="contained"
            color="primary"
            size="medium"
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="action"
          >
            Actions
          </label>

          {actionList.length === 0 && (
            <span className="text-gray-700 text-sm font-bold">
              No actions available, try creating one on the actions page. Click{' '}
              <Link to="/actions">here</Link>
            </span>
          )}

          {actionList.length > 0 && (
            <MultiSelectBox
              key={defaultActions?.join('-')}
              defaultValue={defaultActionList}
              options={actionList}
              variant="contained"
              color="primary"
              size="medium"
              className="min-w-[140px]"
              onChange={(value) => setAgent({ ...agent, actions: value })}
              disabled={loading}
            />
          )}
        </div>
        <div className="mb-3">
          <div className="rounded-lg w-full font-semibold">
            <Disclosure as="div" className="py-2" defaultOpen={false}>
              <DisclosureButton className="group flex w-full items-center justify-between">
                <span className="text-sm/6 font-bold">Advanced Settings</span>
                <CaretDown className="group-data-[open]:rotate-180" />
              </DisclosureButton>

              <DisclosurePanel className="mt-2 pt-2 text-sm/5 text-white/50 border-t-2 border-white/50">
                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="language"
                  >
                    Language
                  </label>

                  <SelectBox
                    options={languages}
                    key={agent.language}
                    defaultValue={{
                      value: agent.language,
                      label: languages.find(
                        (lang) => lang.value === agent.language,
                      )?.label,
                    }}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onChange={(value) =>
                      setAgent({ ...agent, language: value as Language })
                    }
                    className="min-w-[140px]"
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="provider"
                  >
                    LLM Provider
                  </label>

                  <SelectBox
                    options={llmProviders}
                    key={agent.provider}
                    defaultValue={{
                      value: agent.provider || 'openai',
                      label: llmProviders.find(
                        (provider) =>
                          provider.value === (agent.provider || 'openai'),
                      )?.label,
                    }}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onChange={(value) =>
                      setAgent({
                        ...agent,
                        provider: value as LLMProvider,
                        model: undefined,
                      })
                    }
                    className="min-w-[140px]"
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="model"
                  >
                    Model Name
                  </label>

                  <SelectBox
                    options={providerModels[agent.provider || 'openai']}
                    key={agent.model}
                    defaultValue={
                      agent.model
                        ? {
                            value: String(agent.model),
                            label: providerModels[
                              agent.provider || 'openai'
                            ].find((model) => model.value === agent.model)
                              ?.label,
                          }
                        : undefined
                    }
                    variant="contained"
                    color="primary"
                    size="medium"
                    onChange={(value) =>
                      setAgent({
                        ...agent,
                        model: value as string,
                      })
                    }
                    className="min-w-[140px]"
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="conversationSpeed"
                  >
                    Conversation Speed{' '}
                  </label>

                  <input
                    type="range"
                    id="conversationSpeed"
                    min="0.2"
                    max="5"
                    step="0.2"
                    value={agent.conversation_speed}
                    onChange={(e) =>
                      setAgent({
                        ...agent,
                        conversation_speed: parseFloat(e.target.value),
                      })
                    }
                    className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb"
                  />

                  <span className="text-gray-700/70 text-sm font-bold">
                    {agent.conversation_speed}
                  </span>
                </div>
                <div className="mb-3 flex justify-between">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="enableRecording"
                  >
                    Enable Recording{' '}
                  </label>

                  <input
                    type="checkbox"
                    id="enableRecording"
                    checked={agent.enable_recording}
                    onChange={(e) => {
                      setAgent({
                        ...agent,
                        enable_recording: e.target.checked,
                      });
                    }}
                    className="w-4 bg-control-plane-300 rounded-lg cursor-pointer"
                  />
                </div>
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>

        <div className="mb-3 col-span-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Prompt Templates
          </label>
          <div className="flex space-x-6">
            {Object.keys(AGENT_TEMPLATES).map((key) => {
              const label = key
                .split('_')
                .map(
                  (labelPart) =>
                    `${labelPart[0].toUpperCase()}${labelPart.substring(1)}`,
                )
                .join(' ');
              return (
                <Button
                  key={key}
                  color="secondary"
                  className="flex flex-col items-center w-48"
                  onClick={() => setPromptContent(AGENT_TEMPLATES[key])}
                >
                  {TEMPLATE_ICONS[key]}
                  <div className="mt-2">{label}</div>
                </Button>
              );
            })}
          </div>
        </div>
        <div className="mb-6 col-span-2">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 required-field"
            htmlFor="text"
          >
            Prompt
          </label>
          <textarea
            id="text"
            placeholder="Prompt text"
            value={promptContent}
            onChange={handlePromptEdit}
            className="w-full h-40 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400 md:h-80"
          />
        </div>
      </div>
    </div>
  );
};
