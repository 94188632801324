import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export const processPhoneNumber = (number: string) => {
  const formattedNumber = `${number.includes('+') ? '' : '+'}${number}`;

  if (!isValidPhoneNumber(formattedNumber)) return number;

  const phoneNumber = parsePhoneNumber(formattedNumber);
  return phoneNumber?.formatInternational() ?? '';
};

export const getFirstAndLastFourUUID = (uuid: string) => {
  if (!uuid) return '';
  return `${uuid.slice(0, 4)}....${uuid.slice(-4)}`;
};
