import { Pencil } from '@phosphor-icons/react';
import { useState } from 'react';
import { clearCachedToken, patch, post, renewToken } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { Option } from 'src/components/SelectBox';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Organization } from 'src/interfaces/organization.interface';

interface OrganizationsProps {
  organizations: Organization[];
  setOrganizations: (organizations: Organization[]) => void;
  currentOrg: Option | undefined;
  setCurrentOrg: (currentOrg: Option) => void;
}

export const Organizations = ({
  organizations,
  setOrganizations,
  currentOrg,
  setCurrentOrg,
}: OrganizationsProps) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;
  const notification = useNotification();

  const [editingOrg, setEditingOrg] = useState<Organization | null>(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [newName, setNewName] = useState('');

  const handleEditOrg = (org: Organization) => {
    setEditingOrg(org);
    setNewName(org.name);
    setModalOpen(true);
  };

  const updateOrganization = async () => {
    if (!editingOrg) return;

    setLoading(true);
    try {
      await patch(`/organizations/${editingOrg.id}`, {
        name: newName,
      });

      const updatedOrganizations = organizations.map((org) =>
        org.id === editingOrg.id ? { ...org, name: newName } : org,
      );
      setOrganizations(updatedOrganizations);

      if (currentOrg?.value === editingOrg.id) {
        setCurrentOrg({
          label: newName,
          value: editingOrg.id,
        });

        await post(
          `/users/sync`,
          {},
          {
            envId,
          },
        );

        clearCachedToken();

        await renewToken();
      }

      setModalOpen(false);

      notification.success('Organization updated successfully');
    } catch {
      notification.error('Failed to update organization');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-6">
      <h2 className="text-xl font-semibold text-control-plane-400 mb-4">
        Organizations
      </h2>

      <div className="space-y-4">
        {organizations.map((org) => (
          <div
            key={org.id}
            className="flex items-center justify-between p-4 bg-white rounded-lg shadow border border-gray-200"
          >
            <div>
              <h3 className="font-medium">
                {org.name}{' '}
                {currentOrg?.value === org.id ? <b>(Current)</b> : ''}
              </h3>
              <p className="text-sm text-gray-500">ID: {org.id}</p>
            </div>

            <Button
              onClick={() => handleEditOrg(org)}
              color="primary"
              size="large"
            >
              <Pencil className="w-4 h-4" />
            </Button>
          </div>
        ))}
      </div>

      <Modal
        title="Edit Organization"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        actionButton={
          <Button
            onClick={updateOrganization}
            disabled={loading || !newName || newName === editingOrg?.name}
          >
            Update Organization
          </Button>
        }
      >
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Organization Name
            </label>

            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
