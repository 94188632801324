import { AccountConnection } from 'src/interfaces/accountConnection.interface';
import { get } from './requests';

const testAzureSpech = async (region: string, key: string) => {
  try {
    const response = await fetch(
      `https://${region}.stt.speech.microsoft.com/speech/recognition/conversation/cognitiveservices/v1?language=en-US`,
      {
        method: 'POST',
        headers: {
          'Ocp-Apim-Subscription-Key': key,
        },
        body: JSON.stringify({}),
      },
    );
    return response.ok;
  } catch {
    return false;
  }
};

const testApiEndpoint = async (
  url: string,
  headers: Record<string, string>,
) => {
  try {
    const response = await fetch(url, { headers });
    return response.ok;
  } catch {
    return false;
  }
};

const testPlayHT = async (apiKey: string, userId: string, envId: string) => {
  try {
    const items = await get('/voices/playht', {
      envId,
      headers: {
        'playht-api-key': apiKey,
        'playht-user-id': userId,
      },
    });
    return Array.isArray(items);
  } catch {
    return false;
  }
};

export const testVoice = async (provider: AccountConnection, envId: string) => {
  switch (provider.type) {
    case 'account_connection_azure_speech': {
      return await testAzureSpech(
        provider.credentials.region,
        provider.credentials.api_key,
      );
    }
    case 'account_connection_elevenlabs': {
      return await testApiEndpoint('https://api.elevenlabs.io/v1/voices', {
        'xi-api-key': provider.credentials.api_key,
      });
    }
    case 'account_connection_playht': {
      return await testPlayHT(
        provider.credentials.api_key,
        provider.credentials.userId,
        envId,
      );
    }
    case 'account_connection_cartesia': {
      return await testApiEndpoint('https://api.cartesia.ai/voices', {
        'Cartesia-Version': '2024-06-10',
        'X-API-Key': provider.credentials.api_key,
      });
    }
    default: {
      return false;
    }
  }
};
