import {
  ArrowSquareOut,
  Code,
  MagnifyingGlass,
  Pencil,
  Trash,
  UserSound,
  X,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { get, patch, post, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Modal } from 'src/components/Modal';
import { SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { AccountConnection } from 'src/interfaces/accountConnection.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import {
  RimeVoiceDto,
  Voice,
  VoicePayload,
} from 'src/interfaces/voice.interface';
import { testVoice } from '../../api/providerConnection';
import { listenVoice } from '../../api/voice';
import { Tooltip } from 'src/components/Tooltip';

type VoiceQuality = 'faster' | 'draft' | 'low' | 'medium' | 'high' | 'premium';

const voices = [
  { label: 'Azure', value: 'voice_azure' },
  { label: 'Eleven Labs', value: 'voice_eleven_labs' },
  { label: 'Play HT', value: 'voice_play_ht' },
  { label: 'Rime', value: 'voice_rime' },
  { label: 'Cartesia', value: 'voice_cartesia' },
];

const newVoice: VoicePayload = {
  type: 'voice_azure',
  voice_name: '',
  label: '',
  description: '',
  rate: 0,
  pitch: 0,
  version: '',
  speed_alpha: 1,
  model_id: 'mist',
};

const voicesAndProviders = [
  { voiceType: 'voice_azure', providerType: 'account_connection_azure_speech' },
  {
    voiceType: 'voice_eleven_labs',
    providerType: 'account_connection_elevenlabs',
  },
  { voiceType: 'voice_play_ht', providerType: 'account_connection_playht' },
  { voiceType: 'voice_rime', providerType: 'account_connection_rime' },
  { voiceType: 'voice_cartesia', providerType: 'account_connection_cartesia' },
];

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'type', label: 'Provider', width: '10%' },
  { key: 'label', label: 'Voice/Model', width: '10%' },
  { key: 'description', label: 'Description', width: '50%' },
  { key: 'actions', label: 'Action', width: '20%', disableSorting: true },
];

const convertToDto = (form: VoicePayload) => {
  switch (form.type) {
    case 'voice_azure':
      return {
        type: form.type,
        voice_name: form.voice_name,
        label: form.label,
        description: form.description,
        rate: form.rate,
        pitch: form.pitch,
      };
    case 'voice_eleven_labs':
      return {
        type: form.type,
        voice_id: form.voice_name,
        label: form.label,
        description: form.description,
        stability: form.stability,
        similarity_boost: form.similarity_boost,
        model_id: form.model_id,
        optimize_streaming_latency: form.optimize_streaming_latency,
      };
    case 'voice_play_ht':
      return {
        type: form.type,
        voice_id: form.voice_name,
        label: form.label,
        description: form.description,
        version: form.version,
        speed: form.speed,
        temperature: form.temperature,
        top_p: form.top_p,
        text_guidance: form.text_guidance,
        voice_guidance: form.voice_guidance,
      };
    case 'voice_rime':
      return {
        type: form.type,
        speaker: form.voice_name,
        label: form.label,
        description: form.description,
        speed_alpha: form.speed_alpha,
        model_id: form.model_id,
      };
    case 'voice_cartesia':
      return {
        type: form.type,
        voice_id: form.voice_name,
        label: form.label,
        description: form.description,
        model: form.model,
        speed: form.speed,
        emotion: form.emotion,
      };
    default:
      return form;
  }
};

const getPlaceholderByProvider = (type: string) => {
  switch (type) {
    case 'voice_azure':
      return 'e.g. en-US-JennyNeural';
    case 'voice_eleven_labs':
      return 'e.g. bIHbv24MWmeRgasZH58o';
    case 'voice_play_ht':
      return 'e.g. s3://voice-cloning-zero-shot/[id]/manifest.json';
    case 'voice_rime':
      return 'e.g. speaker_name';
    case 'voice_cartesia':
      return 'e.g. voice_id';
    default:
      return '';
  }
};

const getAdditionalFields = (
  type: string,
  form: VoicePayload,
  setForm: (form: VoicePayload) => void,
) => {
  switch (type) {
    case 'voice_azure':
      return (
        <>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Rate
            </label>
            <input
              type="number"
              value={form?.rate ?? 0}
              onChange={(e) =>
                setForm({
                  ...form,
                  rate: Number(e.target.value),
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Pitch
            </label>
            <input
              type="number"
              value={form.pitch}
              onChange={(e) =>
                setForm({
                  ...form,
                  pitch: Number(e.target.value),
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>
        </>
      );
    case 'voice_play_ht':
      return (
        <>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Version
            </label>
            <input
              type="text"
              value={form.version}
              disabled
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Speed<i className="text-gray-400 ml-1">({form.speed})</i>
            </label>
            <input
              type="range"
              step="0.1"
              min="0.1"
              max="2"
              value={form.speed}
              onChange={(e) =>
                setForm({
                  ...form,
                  speed: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb focus:ring-control-plane-400"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Quality
            </label>
            <SelectBox
              options={[
                { value: 'faster', label: 'Faster' },
                { value: 'draft', label: 'Draft' },
                { value: 'low', label: 'Low' },
                { value: 'medium', label: 'Medium' },
                { value: 'high', label: 'High' },
                { value: 'premium', label: 'Premium' },
              ]}
              onChange={(value) =>
                setForm({
                  ...form,
                  quality: value as VoiceQuality,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Temperature{' '}
              <i className="text-gray-400 ml-1">({form.temperature})</i>
            </label>
            <input
              type="range"
              step="0.1"
              min="0"
              max="1"
              value={form.temperature}
              onChange={(e) =>
                setForm({
                  ...form,
                  temperature: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb  focus:ring-control-plane-400"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Top P <i className="text-gray-400 ml-1">({form.top_p})</i>
            </label>
            <input
              type="range"
              step="0.1"
              min="0"
              max="1"
              value={form.top_p}
              onChange={(e) =>
                setForm({
                  ...form,
                  top_p: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb  focus:ring-control-plane-400"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Text Guidance
            </label>
            <input
              type="text"
              value={form.text_guidance || ''}
              onChange={(e) =>
                setForm({
                  ...form,
                  text_guidance: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Voice Guidance
            </label>
            <input
              type="text"
              value={form.voice_guidance || ''}
              onChange={(e) =>
                setForm({
                  ...form,
                  voice_guidance: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>
        </>
      );
    case 'voice_rime':
      return (
        <>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Speed Alpha{' '}
              <i className="text-gray-400 ml-1">({form.speed_alpha})</i>
            </label>
            <input
              type="range"
              min="0.1"
              max="2"
              step="0.1"
              value={form.speed_alpha}
              onChange={(e) =>
                setForm({
                  ...form,
                  speed_alpha: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Model ID
            </label>
            <SelectBox
              options={[
                { value: 'mist', label: 'Mist' },
                { value: 'v1', label: 'V1' },
              ]}
              onChange={(value) =>
                setForm({
                  ...form,
                  model_id: value as 'mist' | 'v1',
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>
        </>
      );
    case 'voice_cartesia':
      return (
        <>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Model
            </label>
            <input
              type="text"
              value={form.model || ''}
              onChange={(e) =>
                setForm({
                  ...form,
                  model: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              placeholder="e.g. model_name"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Speed <i className="text-gray-400 ml-1">({form.speed})</i>
            </label>
            <input
              type="range"
              step="0.1"
              min="0.1"
              max="2"
              value={form.speed}
              onChange={(e) =>
                setForm({
                  ...form,
                  speed: Number(e.target.value),
                })
              }
              className="w-full h-2 bg-control-plane-300 rounded-lg appearance-none cursor-pointer range-thumb  focus:ring-control-plane-400"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Emotion
            </label>
            <input
              type="text"
              value={form.emotion?.join(', ') || ''}
              onChange={(e) =>
                setForm({
                  ...form,
                  emotion: e.target.value.split(',').map((s) => s.trim()),
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              placeholder="e.g. happy, excited (comma separated)"
            />
          </div>
        </>
      );
    case 'voice_eleven_labs':
      return (
        <>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Stability
            </label>
            <input
              type="number"
              step="0.1"
              value={form.stability}
              onChange={(e) =>
                setForm({
                  ...form,
                  stability: Number(e.target.value),
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Similarity Boost
            </label>
            <input
              type="number"
              step="0.1"
              value={form.similarity_boost}
              onChange={(e) =>
                setForm({
                  ...form,
                  similarity_boost: Number(e.target.value),
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Model ID
            </label>
            <SelectBox
              options={[
                { value: 'eleven_multilingual_v2', label: 'Multilingual v2' },
                { value: 'eleven_flash_v2_5', label: 'Flash v2.5' },
                { value: 'eleven_flash_v2', label: 'Flash v2' },
                {
                  value: 'eleven_multilingual_sts_v2',
                  label: 'Multilingual STS v2',
                },
                { value: 'eleven_english_sts_v2', label: 'English STS v2' },
                { value: 'eleven_turbo_v2_5', label: 'Turbo v2.5' },
                { value: 'eleven_turbo_v2', label: 'Turbo v2' },
              ]}
              onChange={(value) =>
                setForm({
                  ...form,
                  model_id: value as 'mist' | 'v1',
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="mb-6">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={form.optimize_streaming_latency || false}
                onChange={(e) =>
                  setForm({
                    ...form,
                    optimize_streaming_latency: e.target.checked,
                  })
                }
                className="rounded border-gray-300 text-control-plane-500 focus:ring-control-plane-500"
              />
              <span className="text-gray-700 text-sm font-bold">
                Optimize Streaming Latency
              </span>
            </label>
          </div>
        </>
      );
    default:
      return null;
  }
};

export const ListVoices = () => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [rows, setRows] = useState<Voice[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [equivalentCodeOpen, setEquivalentCodeOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] = useState<VoicePayload>(newVoice);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const [providers, setProviders] = useState<AccountConnection[]>([]);
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const [ttsModalOpen, setTtsModalOpen] = useState(false);
  const [ttsText, setTtsText] = useState('');
  const [selectedVoiceForTTS, setSelectedVoiceForTTS] = useState<Voice | null>(
    null,
  );

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);
    setEquivalentCodeOpen(false);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      ...(filter.length !== 0 && {
        filters: JSON.stringify({ label: filter }),
      }),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/voices?${query.toString()}`, {
      envId,
    });

    const items = data.items;

    setRows(items);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, sortConfig, debouncedFilter, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    handlePageChange(1, size);
  };

  const resetForm = () => {
    setForm(newVoice);
  };

  const cleanForm = (type: string) => {
    setForm({
      voice_name: '',
      type,
      ...(type === 'voice_cartesia' && {
        speed: 1,
      }),
      ...(type === 'voice_play_ht' && {
        version: '3-mini',
        speed: 1,
        temperature: 0,
        top_p: 0,
      }),
      ...(type === 'voice_rime' && {
        speed_alpha: 0,
      }),
    });
  };

  const openModalNewVoice = () => {
    setModalOpen(true);
    setSelectedId('');
    resetForm();
  };

  const openModalEditVoice = async (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
    const voice = rows.find((item) => item.id === id);

    if (voice) {
      setForm({
        ...voice,
        voice_name:
          voice.voice_name || voice.voice_id || (voice as RimeVoiceDto).speaker,
      });
    }
  };

  const closeModal = () => {
    setEquivalentCodeOpen(false);
    setModalOpen(false);
    setSelectedId('');
    setForm(newVoice);
    resetForm();
  };

  const submitModal = async () => {
    setLoading(true);

    const isProviderConnected = await validateVoice(form.type, String(envId));

    if (!isProviderConnected) {
      setLoading(false);
      return;
    }

    if (selectedId) {
      await patch(`/voices/${selectedId}`, convertToDto(form), {
        envId,
      });
      notification.success('Voice updated successfully');
    } else {
      await post('/voices', convertToDto(form), {
        envId,
      });
      notification.success('Voice created successfully');
    }

    fetchData(currentPage, size);

    setLoading(false);

    closeModal();
  };

  const handleEndpointVoice = () => {
    if (selectedId) {
      return `voices/update?id=${selectedId}`;
    } else {
      return 'voices/create';
    }
  };

  const openDeleteVoice = (voice: Voice) => {
    setModalDeleteOpen(true);
    setSelectedId(voice.id);
  };

  const handleRemoveVoice = async () => {
    setLoading(true);
    try {
      await remove(`/voices/${selectedId}`, { envId });
      notification.success('Voice deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete voice');
    } finally {
      setLoading(false);
    }
  };

  const validateVoice = async (provider: string, envId: string) => {
    const providerType = voicesAndProviders.find(
      (p) => p.voiceType === provider,
    );

    if (!providerType) {
      notification.error('Provider not connected');
      return false;
    }

    const isProviderConnected = providers.find(
      (p) => p.type === providerType?.providerType,
    );

    if (!isProviderConnected) {
      notification.error('Provider not connected');
      return false;
    }

    const isVoiceConnected = await testVoice(isProviderConnected, envId);

    if (!isVoiceConnected) {
      notification.error('Voice could not be verified');
      return false;
    }

    return true;
  };

  const handlePlayTTS = (voice: Voice) => {
    setSelectedVoiceForTTS(voice);
    setTtsText('');
    setTtsModalOpen(true);
  };

  const handleConfirmTTS = async () => {
    if (!selectedVoiceForTTS?.id || !ttsText) return;

    setPlayingVoice(selectedVoiceForTTS.id);
    setTtsModalOpen(false);

    const provider = providers.find(
      (p) =>
        voicesAndProviders.find(
          (vp) => vp.voiceType === selectedVoiceForTTS.type,
        )?.providerType === p.type,
    );

    if (!provider) {
      notification.error('Provider not connected');
      setPlayingVoice(null);
      return;
    }

    const success = await listenVoice(
      selectedVoiceForTTS,
      ttsText,
      String(envId),
      provider,
    );

    if (!success) {
      notification.error('Failed to play voice');
    }

    setPlayingVoice(null);
    setSelectedVoiceForTTS(null);
  };

  useEffect(() => {
    if (equivalentCodeOpen && !loading) {
      setEquivalentCodeOpen(true);
    }
  }, [form, equivalentCodeOpen]);

  useEffect(() => {
    const fetchProviders = async () => {
      const { items: data } = await get('/account-connections', { envId });
      if (data) setProviders(data);
    };
    fetchProviders();
  }, []);

  const isProviderConnected = (provider: string) => {
    return providers.find(
      (p) =>
        voicesAndProviders.find((vp) => vp.voiceType === provider)
          ?.providerType === p.type,
    );
  };

  return (
    <div className="flex-1">
      <Heading
        title="Voice Library"
        subtitle="Voices add personality to your conversation by making it feel more human."
      >
        <div className="flex mt-6">
          <Button className="w-40" onClick={openModalNewVoice}>
            Import Voice
          </Button>

          <Button
            className="ml-4 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/voices"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            required
            placeholder="Search voices"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={submitFilter}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={
            rows.map((item: Voice) => ({
              ...item,
              id: <CopyableField value={item.id} notification={notification} />,
              type: voices.find((voice) => voice.value === item.type)?.label,
              voice_name: item.label,
              actions: (
                <div className="flex">
                  {isProviderConnected(item.type) && (
                    <Tooltip content="Test voice">
                      <Button
                        className="ml-2"
                        onClick={() => handlePlayTTS(item)}
                        disabled={!!playingVoice}
                      >
                        {playingVoice === item.id ? (
                          <div className="w-5 h-5 animate-spin rounded-full border-2 border-control-plane-500 border-t-transparent" />
                        ) : (
                          <UserSound className="cursor-pointer inline-block w-5 h-5" />
                        )}
                      </Button>
                    </Tooltip>
                  )}

                  {item.user_id && (
                    <Button
                      className="ml-2"
                      onClick={() => openModalEditVoice(item.id as string)}
                    >
                      <Pencil className="cursor-pointer inline-block w-5 h-5" />
                    </Button>
                  )}

                  {item.user_id && (
                    <Button
                      className="ml-2"
                      onClick={() => openDeleteVoice(item)}
                      color="error"
                    >
                      <Trash className="cursor-pointer inline-block w-5 h-5" />
                    </Button>
                  )}
                </div>
              ),
            })) as never[]
          }
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>

      <Modal
        title={`${selectedId ? 'Edit' : 'Import'} Voice`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button
            color="default"
            onClick={submitModal}
            disabled={loading || !form.voice_name || !form.type || !form.label}
          >
            {selectedId ? 'Save Voice' : 'Import Voice'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              equivalentCodeOpen
                ? setEquivalentCodeOpen(false)
                : setEquivalentCodeOpen(true)
            }
            className="flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="flex justify-end italic text-sm mt-4">
          * Required fields
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 required-field"
              htmlFor="provider"
            >
              Provider
            </label>

            <SelectBox
              key={`voice_type_${selectedId}_${form.type}`}
              options={
                form.type === 'voice_rime'
                  ? voices
                  : voices.filter((v) => v.value !== 'voice_rime')
              }
              defaultValue={voices.find((v) => v.value === form.type)}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) => {
                setForm({
                  ...form,
                  type: value,
                });

                cleanForm(value);
              }}
              className="min-w-[140px]"
              disabled={loading}
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 required-field"
              htmlFor="label"
            >
              Label
            </label>

            <input
              type="text"
              id="label"
              value={form.label}
              onChange={(e) =>
                setForm({
                  ...form,
                  label: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              placeholder="Enter a descriptive name"
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 required-field"
              htmlFor="voice-name"
            >
              Voice ID
            </label>

            <input
              type="text"
              id="voice-name"
              value={form.voice_name}
              onChange={(e) =>
                setForm({
                  ...form,
                  voice_name: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              placeholder={getPlaceholderByProvider(form.type)}
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              Description
            </label>

            <input
              type="text"
              id="description"
              value={form.description}
              onChange={(e) =>
                setForm({
                  ...form,
                  description: e.target.value,
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            />
          </div>
        </div>

        {getAdditionalFields(form.type, form, setForm)}

        {equivalentCodeOpen && (
          <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="block text-gray-700 text-md font-bold mb-2">
                Equivalent Code
              </h3>

              <X
                className="cursor-pointer inline-block w-5 h-5"
                onClick={() => setEquivalentCodeOpen(false)}
              />
            </div>

            <EquivalentCode
              payload={form}
              endpoint={handleEndpointVoice()}
              method="POST"
            />
          </div>
        )}
      </Modal>

      <Modal
        title="Delete Voice"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedId('');
        }}
        actionButton={
          <Button color="default" onClick={handleRemoveVoice}>
            Delete Voice
          </Button>
        }
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this voice?
        </div>
      </Modal>

      <Modal
        title="Test Voice"
        isOpen={ttsModalOpen}
        onClose={() => {
          setTtsModalOpen(false);
          setSelectedVoiceForTTS(null);
        }}
        actionButton={
          <Button
            color="default"
            onClick={handleConfirmTTS}
            disabled={!ttsText || ttsText.length > 20}
          >
            Play Voice
          </Button>
        }
        className="w-96"
      >
        <div className="mb-6">
          <div className="mb-4">
            <div className="text-sm text-gray-500">Voice Name</div>
            <div className="font-medium">{selectedVoiceForTTS?.label}</div>
            <div className="text-sm text-gray-500 mt-2">Provider</div>
            <div className="font-medium">
              {
                voices.find(
                  (voice) => voice.value === selectedVoiceForTTS?.type,
                )?.label
              }
            </div>
          </div>

          <label className="block text-gray-700 text-sm font-bold mb-2">
            Enter text to speak (max 20 characters)
          </label>
          <textarea
            value={ttsText}
            onChange={(e) => setTtsText(e.target.value)}
            maxLength={20}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400 h-24"
            placeholder="Enter text here..."
          />
          <div className="text-right text-sm text-gray-500 mt-1">
            {ttsText.length}/20 characters
          </div>
        </div>
      </Modal>
    </div>
  );
};
