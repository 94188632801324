import { Code } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get, patch, post } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { PromptPayload } from 'src/interfaces/prompt.interface';

type PromptProps = {
  editing?: boolean;
};

export const ManagePrompt = ({ editing = false }: PromptProps) => {
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const navigate = useNavigate();
  const { id: editId } = useParams();

  const notification = useNotification();

  const [dateSaved, setDateSaved] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState<PromptPayload>({ content: '' });

  const handleSave = async () => {
    setLoading(true);

    if (editing) {
      await patch(`/prompts/${editId}`, prompt, {
        envId,
      });
      notification.success('Prompt updated successfully 🎉');
    } else {
      const createPrompt = await post('/prompts', prompt, {
        envId,
      });
      navigate(`/prompt/edit/${createPrompt.id}`);
      notification.success('Prompt created successfully 🎉');
    }

    setDateSaved(new Date());
    setLoading(false);
  };

  const endpoint = editing ? `prompts/update?id=${editId}` : 'prompts/create';

  const handlePromptEquivalentCode = () => {
    openSidebar(
      <EquivalentCode payload={prompt} endpoint={endpoint} method="POST" />,
      'Equivalent Code',
    );
  };

  useEffect(() => {
    if (editing) {
      const fetchPrompt = async () => {
        setLoading(true);
        const prompt = await get(`/prompts/${editId}`, {
          envId,
        });
        setPrompt(prompt);
        setLoading(false);
      };

      fetchPrompt();
    }
  }, [editing, editId]);

  useEffect(() => {
    if (isOpen && !loading) {
      handlePromptEquivalentCode();
    }
  }, [prompt, isOpen]);

  return (
    <div className="flex-1">
      <Heading
        title={
          editing ? `Editing Prompt: ${prompt.label || editId}` : 'New Prompt'
        }
      />

      <ContentWrapper>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="w-full md:col-span-2 lg:col-span-2 text-sm text-gray-500 mt-2">
            <input
              type="text"
              id="label"
              placeholder="Name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              value={prompt.label}
              onChange={(e) => setPrompt({ ...prompt, label: e.target.value })}
              disabled={loading}
            />
          </div>

          <Button
            onClick={() =>
              isOpen ? closeSidebar() : handlePromptEquivalentCode()
            }
            className="mt-2 w-full md:col-span-1 lg:col-span-1 flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <div className="md:col-span-2">
            <textarea
              className="w-full border border-gray-300 rounded-md p-2 h-64"
              placeholder="Prompt Content"
              value={prompt.content}
              onChange={(e) =>
                setPrompt({ ...prompt, content: e.target.value })
              }
              disabled={loading || (!prompt.user_id && !!editId)}
            ></textarea>

            <div className="flex items-center mt-4 justify-end">
              {!editing && (
                <Button
                  color="error"
                  className="mr-4"
                  onClick={() => navigate('/prompts')}
                  disabled={loading}
                >
                  Cancel
                </Button>
              )}

              <Button
                onClick={handleSave}
                disabled={
                  loading || !prompt.content || (!prompt.user_id && !!editId)
                }
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>

              {dateSaved && !loading && (
                <p className="ml-4 text-green-500">
                  Last saved at {dateSaved.toLocaleTimeString()}
                </p>
              )}
            </div>
          </div>

          <div>
            <div className="bg-gray-100 p-4 rounded-md">
              <h2 className="text-xl font-semibold">Prompt Guidance</h2>
              <p className="text-gray-600 text-sm mt-2">
                Here are some tips for creating a great prompt:
                <ul className="list-disc ml-5 mt-2">
                  <li>
                    Avoid overly complex instructions. Simple instructions are
                    more reliable
                  </li>
                  <li>
                    If using uncommon words or proper nouns, write it out
                    phonetically
                  </li>
                  <li>
                    When working with large prompts, split it into sections
                  </li>
                  <li>Provide some examples of desired behavior</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
